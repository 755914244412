import React from "react"
import { Layout, SEO } from "../components"
import {
  ArchitectureSection,
  ClientsSection,
  FormSection,
  HeroSection,
  InnovationSection,
  OurWorkSection,
  ServicesSection,
  StructuredSection,
  TestimonialsSection,
} from "../pageSections/index"
import { index, testimonials } from "../content"
import { useContentfulOurWork } from "../components/hooks/useContentfulOurWork"

const IndexPage = () => {
  const {
    contentGroup1,
    contentGroup2,
    heroText,
    innovations,
    metadata,
    services,
  } = index

  const { title, shortDescription, projects } = useContentfulOurWork()

  return (
    <Layout title={metadata.title}>
      <SEO description={metadata.description} title={metadata.title} />
      <HeroSection heroText={heroText} />
      <ClientsSection />
      <InnovationSection content={innovations} />
      <ServicesSection color="purple" services={services} />
      <StructuredSection content={contentGroup1} darkMode={true} />
      <ArchitectureSection content={contentGroup2} />
      <OurWorkSection
        color="purple"
        metadata={{ title, shortDescription }}
        projects={projects}
      />
      <TestimonialsSection testimonials={testimonials} />
      <FormSection />
    </Layout>
  )
}

export default IndexPage
