import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { MobileContentCarousel, StackedContent } from "../../components"

const StructuredSection = (props) => {
  const { content, darkMode } = props
  const { images } = useStaticQuery(graphql`
    query {
      images: allFile(
        filter: { relativeDirectory: { eq: "images/structuredSection" } }
      ) {
        nodes {
          id
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  `)

  return (
    <>
      <section className="dark structured">
        <h2>Fortune 100 Clients</h2>
        <div className="mobile-only">
          <MobileContentCarousel
            content={content}
            images={images.nodes}
            darkMode={darkMode}
          />
        </div>
        <div className="desktop-only">
          <StackedContent
            content={content}
            images={images.nodes}
            darkMode={darkMode}
          />
        </div>
      </section>
    </>
  )
}

export default StructuredSection
