import React from "react"
import PropTypes from "prop-types"

const InnovationSection = (props) => {
  const { content } = props
  return (
    <section className="text-section">
      <div className="light-background">
        <div className="container">
          <div className="layout">
            <h2 className="blue-text h3">{content.heading}</h2>
            <p className="blue-text">{content.description}</p>
          </div>
        </div>
      </div>
    </section>
  )
}

InnovationSection.propTypes = {
  content: PropTypes.shape({
    heading: PropTypes.string,
    description: PropTypes.string,
  }),
}

export default InnovationSection
