import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { LogoBar } from "../../components"

const ClientsSection = () => {
  const { images } = useStaticQuery(graphql`
    query {
      images: allFile(
        filter: { relativeDirectory: { eq: "images/clientLogos/index" } }
      ) {
        nodes {
          id
          name
          childImageSharp {
            gatsbyImageData(layout: FIXED, width: 210)
          }
        }
      }
    }
  `)
  return (
    <section className={"white"}>
      <LogoBar images={images.nodes} />
    </section>
  )
}

export default ClientsSection
