import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

const HeroSection = (props) => {
  const { background, image, mobileImage } = useStaticQuery(graphql`
    query {
      background: file(
        relativePath: { eq: "images/heros/index-hero-background.jpg" }
      ) {
        id
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      image: file(relativePath: { eq: "images/heros/index-hero-image.png" }) {
        id
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      mobileImage: file(
        relativePath: { eq: "images/heros/index-hero-mobile-image.png" }
      ) {
        id
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)

  return (
    <div className="background-image-wrapper">
      <GatsbyImage
        image={background.childImageSharp.gatsbyImageData}
        className="background-image"
      />
      <section className="hero">
        <div className="container row-reverse">
          <div className="five columns image-container">
            <GatsbyImage
              alt="Digitial Dreams"
              className="desktop-only"
              image={image.childImageSharp.gatsbyImageData}
            />
            <GatsbyImage
              alt="Digitial Dreams"
              className="mobile-only"
              image={mobileImage.childImageSharp.gatsbyImageData}
            />
          </div>
          <div className="six columns offset-by-one">
            <h1>{props.heroText}</h1>
            <div className="row button-container">
              <Link to="/our-work">
                <button>See our work</button>
              </Link>
              <Link to="/contact">
                <button>Contact Us</button>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default HeroSection
