import React from "react"
import { MobileProjectCarousel, ProjectsTable } from "../../components"

const OurWorkSection = ({ projects, metadata, color }) => {
  const { title, shortDescription } = metadata
  const textColor =
    (color === "light-gray" || color === "white") && "black-text"
  return (
    <>
      <section className={`${color} row our-work`}>
        <div className="flex-column center">
          <div className="container">
            <h2 className={textColor}>{title}</h2>
            <p className={textColor}>{shortDescription}</p>
            <div className="flex justify-center">
              <div className="green-bar"></div>
            </div>
          </div>
        </div>
      </section>

      <section className="dark our-work">
        <div className="mobile-only">
          <MobileProjectCarousel projects={projects} darkMode={true} />
        </div>

        <div className="desktop-only our-work wrapper">
          <ProjectsTable displayAll={false} projects={projects} />
        </div>
      </section>
    </>
  )
}

export default OurWorkSection
