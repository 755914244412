import React from "react"
import ServicesTable from "../../components/ServicesTable"

const ServicesSection = (props) => {
  const { color, services } = props
  const darkMode = color === "purple"
  const blackText = !darkMode && "black-text"

  return (
    <section className={`${color} table-section`}>
      <div className="container"></div>
      <div className="container">
        <h3 className={blackText}>Services</h3>
      </div>
      <div className="container">
        <div className="ten columns offset-by-one">
          <ServicesTable services={services} darkMode={darkMode} link={true} />
        </div>
      </div>
      <div className="container"></div>
    </section>
  )
}

export default ServicesSection
