/**
 * This section showcases two content blurbs and a fixed image.
 * User can click back and forth between them.
 */

import React, { useState } from "react"
import PropTypes from "prop-types"
import architecture from "../../images/architecture.svg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const ArchitectureSection = (props) => {
  const [selectedIndex, setSelectedIndex] = useState(0)
  const { content } = props

  return (
    <section className="dark architecture">
      <div className="container">
        <div className="eight columns offset-by-two">
          <div className="title-container">
            <h6>{content[selectedIndex]["title"]}</h6>
          </div>

          {/** Image */}
          <div className="content-container">
            <div className="six columns image-container">
              <img alt="Architecture and Efficiency" src={architecture} />
            </div>

            {/** Content */}
            <div className="six columns blurb-container">
              <div className="headings">
                <h4
                  className={`blurb-heading ${
                    selectedIndex === 0 ? "selected" : "unselected"
                  }`}
                  onClick={() => setSelectedIndex(0)}
                >
                  {content[0].heading}
                </h4>
                <h4
                  className={`blurb-heading ${
                    selectedIndex === 1 ? "selected" : "unselected"
                  }`}
                  onClick={() => setSelectedIndex(1)}
                >
                  {content[1].heading}
                </h4>
              </div>
              <div className="description-container">
                <p className="small">{content[selectedIndex].description}</p>
              </div>
            </div>
          </div>

          {/** Buttons */}
          <div className="row arrow-container">
            <div
              className="arrow-button"
              onClick={() => {
                setSelectedIndex(selectedIndex === 1 ? 0 : 1)
              }}
            >
              <FontAwesomeIcon className="arrow left" icon="caret-left" />
            </div>
            <div
              className="arrow-button"
              onClick={() => {
                setSelectedIndex(selectedIndex === 1 ? 0 : 1)
              }}
            >
              <FontAwesomeIcon className="arrow right" icon="caret-right" />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

ArchitectureSection.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      heading: PropTypes.string,
      description: PropTypes.string,
    })
  ),
}

export default ArchitectureSection
