/**
 * This component showcases multiple client testimonials with images.
 * User can click back and forth between them.
 */

import React, { useState } from "react"
import PropTypes from "prop-types"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

const TestimonialsSection = (props) => {
  const [selectedIndex, setSelectedIndex] = useState(0)
  const { testimonials } = props
  const { images, logos } = useStaticQuery(graphql`
    query {
      images: allFile(
        filter: { relativeDirectory: { eq: "images/testimonials" } }
      ) {
        nodes {
          id
          name
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }

      logos: allFile(
        filter: { relativeDirectory: { eq: "images/clientLogos/gray" } }
      ) {
        nodes {
          id
          name
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  `)

  const renderButtons = (numberOfButtons) => {
    const buttons = []
    for (let i = 0; i < numberOfButtons; i++) {
      buttons.push(
        <div
          key={i}
          className={`content-button ${
            selectedIndex === i ? "button-selected" : ""
          }`}
          onClick={() => setSelectedIndex(i)}
        ></div>
      )
    }
    return buttons
  }

  const testimonial = testimonials[selectedIndex]
  const img = images.nodes.find(
    (image) => image.name === testimonial.image.split(".")[0]
  )
  const logo = logos.nodes.find(
    (logo) => logo.name === testimonial.logo.split(".")[0]
  )
  return (
    <section className="blue testimonials">
      <div className="container">
        <div className="nine columns offset-by-one-and-one-half">
          <h6>Teams trust Future Foundry</h6>
          <h3>
            People love Future Foundry.
            <br />
            See some of the stories.
          </h3>

          <div className="layout row">
            {/** Image */}
            <div className="six columns image-container">
              <GatsbyImage
                alt={testimonial.name}
                className="image"
                image={img.childImageSharp.gatsbyImageData}
              />
            </div>

            {/** Content */}
            <div className="six columns content">
              <p className="quote">{testimonial.testimonial}</p>
              <h3>{testimonial.name}</h3>
              <p className="small gray-text">{testimonial.position}</p>
              <div className="logo-container">
                <GatsbyImage
                  alt="company logo"
                  className={logo.name}
                  image={logo.childImageSharp.gatsbyImageData}
                />
              </div>
            </div>
          </div>
          {testimonials.length > 1 && (
            <div className="button-group">
              {renderButtons(testimonials.length)}
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

TestimonialsSection.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      position: PropTypes.string,
      image: PropTypes.string,
      logo: PropTypes.string,
      testimonial: PropTypes.string,
    })
  ),
}

export default TestimonialsSection
