import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import loadable from "@loadable/component"
import { ContactFormIndex, IsVisible } from "../../components"
const Cradle = loadable(() => import("../../components/NewtonsCradleAnimation"))

const FormSection = () => {
  return (
    <section className="form-section blue">
      <div className="container form-container">
        <div className="twelve columns desk-flex">
          <div className="contact-form-left">
            <IsVisible margin="350px">
              <Cradle />
            </IsVisible>
            <div className="social-links desktop-only">
              <a
                href="https://www.linkedin.com/company/futurefoundry"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="linked in"
              >
                <FontAwesomeIcon
                  className="social"
                  aria-hidden="true"
                  icon={["fab", "linkedin"]}
                />
              </a>
              <a
                href="https://github.com/future-foundry"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="github"
              >
                <FontAwesomeIcon
                  aria-hidden="true"
                  className="social"
                  icon={["fab", "github"]}
                />
              </a>
              <a
                href="https://www.facebook.com/futurefoundryco/"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="facebook"
              >
                <FontAwesomeIcon
                  className="social"
                  aria-hidden="true"
                  icon={["fab", "facebook-square"]}
                />
              </a>
            </div>
          </div>
          <div className="contact-form">
            <div className="container">
              <h3>Tell us about your Company</h3>
            </div>
            <div className="container">
              <ContactFormIndex />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default FormSection
